.uU8Nkq_gradient-data {
  font-feature-settings: "clig" off, "liga" off;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(87deg, #fff -46.12%, #25f198 255.23%);
  -webkit-background-clip: text;
  background-clip: text;
  font-family: "Core Sans D 55 Bold";
  font-size: 53px;
  line-height: 2.5rem;
}

.uU8Nkq_overview-card {
  align-self: stretch;
  max-width: max(40%, 500px);
  background: linear-gradient(#fff0 0%, #ffffff03 100%) !important;
  border: 1px solid #f7f8f836 !important;
  border-radius: 1.125rem !important;
  box-shadow: inset 0 4px 4px #00000040 !important;
}

.uU8Nkq_overview-title {
  font-feature-settings: "clig" off, "liga" off;
  line-height: 2.375rem;
  color: #f7f7f7ad !important;
}

.uU8Nkq_overview-body {
  color: #a5b2ac82;
  font-feature-settings: "clig" off, "liga" off;
  line-height: 140%;
}

.uU8Nkq_section-title {
  font-feature-settings: "clig" off, "liga" off;
  grid-template-rows: 2.5rem 3.75rem;
  line-height: 31px;
  display: grid;
}

@media (width <= 768px) {
  .uU8Nkq_section-title {
    grid-template-rows: 2.5rem 2.5rem;
  }
}

.uU8Nkq_section-body {
  font-feature-settings: "clig" off, "liga" off;
  line-height: 1.5rem;
}

.uU8Nkq_dt-title {
  font-feature-settings: "clig" off, "liga" off;
  border-left: 1.5px solid #25f198;
  color: #f7f7f7ad !important;
}

.uU8Nkq_dd-body {
  font-feature-settings: "clig" off, "liga" off;
  line-height: 1.5rem;
  color: #ffffff8c !important;
}

.MgokeW_navbar-bg {
  backdrop-filter: blur(36px);
  border: 1px solid #f7f8f84d;
  border-radius: .625rem;
  width: 90%;
  margin: 2.25rem 5% 0;
  box-shadow: 0 4px 13.4px #0051391f;
  background: #222c !important;
}

@media (width <= 768px) {
  .MgokeW_navbar-bg {
    width: 100%;
    margin: 2.25rem 0 0;
  }
}

.MgokeW_user-info:hover div {
  display: flex !important;
}

.UwycbG_main-title {
  color: #fff;
  font-feature-settings: "clig" off, "liga" off;
  letter-spacing: 1.92px;
  text-transform: uppercase;
  font-family: Monument Extended;
  font-size: 3rem;
  font-weight: 400;
  line-height: 82px;
  text-shadow: 0 4px 24.6px #ffffff63 !important;
}

.UwycbG_ellipse-1 {
  background: radial-gradient(50% 50%, #c261fe1a 0%, #fff0 100%);
  border-radius: 1200px;
  flex-shrink: 0;
  width: 1200px;
  height: 905px;
}

.KBO8SG_rounded-box {
  background: #fff;
  border-radius: 61px 61px 0 0;
  gap: 2.5rem;
  padding: 4.375rem 0 0;
}

.KBO8SG_section-header {
  font-feature-settings: "clig" off, "liga" off;
  text-shadow: 0 4px 24.6px #ffffff63;
  letter-spacing: 1.92px;
  text-transform: uppercase;
  border-bottom: 1px solid var(--grey-a);
  padding: 0 9.375rem;
  font-size: 3rem;
  line-height: 5.125rem;
}

@media (width >= 1200px) {
  .KBO8SG_list-box {
    justify-content: end;
    width: 75%;
    padding-right: 5%;
  }
}

@media (width <= 768px) {
  .KBO8SG_list-box {
    flex-wrap: wrap;
  }
}

.GVqZ8W_zkvrf-header {
  color: #fff;
  font-feature-settings: "clig" off, "liga" off;
  letter-spacing: 11.56px;
  backdrop-filter: blur(5.05px);
  font-family: Monument Extended;
  font-size: 4.25rem;
  line-height: 83px;
  text-shadow: 0 2px 3.2px #ffffff36 !important;
}

@media (width <= 576px) {
  .GVqZ8W_zkvrf-header {
    padding-top: 7rem;
    font-size: 1.5rem;
    line-height: 3rem;
  }
}

@media (width >= 1200px) {
  ._6YvFKG_full-scroll {
    scroll-snap-type: y mandatory;
    background-size: contain;
  }
}

._6YvFKG_full-video {
  object-fit: cover;
  z-index: -10;
  position: absolute;
  top: 0;
  left: 0;
}

._6YvFKG_video-mask {
  z-index: -8;
  width: 100vw;
  height: calc(100vh + 19px);
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(to top, var(--black-a) 0%, #0000 96.45%) !important;
}
/*# sourceMappingURL=index.5c062f04.css.map */
